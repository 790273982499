.message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  > div {
    width: 500px;
    transform: translateY(-10px);
  }
}

.body {
  font-weight: 900;
  margin-top: 20px;
  opacity: 11;
  font-size: 32px;
  line-height: 36px;
}

.subtext {
  margin: 20px 0;
  font-weight: 600;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);

  code {
    font: inherit;
    color: rgba(255, 255, 255, 0.8);
  }
}

.logo {
  display: flex;
  gap: 0px;
  height: 56px;
  align-items: center;

  img {
    width: 56px;
    height: 56px;
    display: block;
  }

  h1 {
    font-size: 24px;
    line-height: 1;
    span {
      display: block;
      font-size: 16px;
      color: var(--faded);
      font-weight: 600;
    }
  }
}

@keyframes pulseOpacity {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .logo {
    animation: pulseOpacity 1s infinite;
  }
}
