.event {
  margin-top: -10px;
  display: block;

  > span {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
    opacity: 0.5;
    display: block;
  }

  > div {
    transition: background 200ms;
    cursor: text;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: rgb(var(--accent), 0.15);
    // border-radius: 6px;
    padding: 4px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 16px;

      &.status {
        opacity: 0.3;
        align-self: center;
      }
    }

    div {
      margin: -2px 0 0;
      font-weight: 600;
      font-size: 14px;
      color: rgb(var(--accent));
    }
  }

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    font: inherit;
    font-weight: 600;
    color: inherit;
    border: 0;
    padding: 4px 8px;

    &::placeholder {
      color: rgb(255, 255, 255, 0.3);
    }
  }

  &.editing {
    > div {
      padding: 0;
    }
  }

  &:hover > div,
  &.editing > div {
    background: rgb(var(--accent), 0.25);
  }
}

.events {
  height: 0;
  overflow: hidden;
  transition: height 200ms;

  &.show {
    height: 37px;
  }

  &.hide {
    height: 0 !important;
  }

  ul {
    margin: 0;
    list-style: none;
    display: flex;
    gap: 16px;
    padding: 8px 12px;

    li {
      font-weight: 600;
      font-size: 15px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      user-select: none;
      transition: 200ms border-color;
      display: flex;
      gap: 4px;
      align-items: center;
      padding-bottom: 2px;

      &:hover {
        border-color: rgb(255, 255, 255, 0.3);
      }

      &.active {
        border-color: rgb(255, 255, 255, 0.5);
      }

      svg {
        width: 20px;
      }
    }
  }
}

.editor:hover .events {
  height: 37px;
  transition-delay: 120ms;
}
