.inputRow {
  display: flex;
  gap: 12px;
  margin: 8px 0 12px;
  align-items: center;
}

.label {
  // display: flex;
  // gap: 4px;
  align-items: center;

  > span {
    display: block;
    font-size: 12px;
    // text-transform: uppercase;
    font-weight: 600;
    opacity: 0.5;
  }
}

.select {
  background: rgb(var(--accent), 0.3);
  color: white;
  font: inherit;
  font-weight: 600;
  padding: 2px 6px 2px 2px;
  border: 0;
  // border-radius: 6px;

  > option {
    background: rgb(25, 25, 25);
  }
}
