.live {
  --accent: 150, 150, 150;
  // --left: 201, 146, 6;
  // --right: 38, 154, 199;

  font-weight: 600;
  // padding: 20px 30px 10px;
  padding: 0 12px;

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h1 {
      margin: 0;
      font-weight: 900;
      font-size: 40px;
    }
  }

  @media (max-width: 1150px) {
    padding: 0 12px;

    h1 {
      font-size: 32px !important;
    }
  }
}
