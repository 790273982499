body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(10, 10, 10);
  color: white;
}

.gradient {
  background: linear-gradient(
    to right,
    rgb(0, 39, 46),
    rgb(10, 10, 10) 40% 60%,
    rgb(46, 24, 0)
  );
}

@media (max-width: 600px) {
  .gradient {
    background: rgb(10, 10, 10);
  }
}

:root {
  --blue: 59, 137, 151;
  --orange: 222, 125, 28;

  --faded: rgba(var(--blue), 0.9);
  --orange-1: #de7d1c;
  --orange-2: #be491f;

  --left: var(--blue);
  --right: var(--orange);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
