.seeds {
  width: 100%;
  white-space: nowrap;
  font-weight: 500;
  border-spacing: 0 4px;

  th {
    text-align: left;
    font-size: 14px;
    opacity: 0.5;
    padding: 4px;
    font-weight: 600;
  }

  td {
    padding: 4px;
    background: rgb(var(--accent), 0.3);
  }

  tr:hover > .icon {
    svg {
      opacity: 0.5;
    }
  }
}

.icon {
  svg {
    position: relative;
    cursor: pointer;
    display: block;
    width: 20px;
    opacity: 0.2;
    transition: 200ms opacity;

    &:hover {
      opacity: 1 !important;
    }
  }

  // border-radius: 6px 0 0 6px;
  &:last-child {
    // border-radius: 0 6px 6px 0;
  }
}

.seed {
  text-align: center !important;
}

.name {
  width: 100%;
}
