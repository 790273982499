.search {
  background: transparent;
  border: 2px solid transparent;
  // border-radius: 6px;
  padding: 0 0 2px 0;
  line-height: 1;
  margin: 0;
  font-family: inherit;
  font-size: 40px;
  font-weight: 900;
  text-align: inherit;
  width: 100%;
  color: inherit;
  outline: none;

  &::placeholder {
    color: rgb(var(--accent), 0.3);
  }

  &:focus {
    transition: none;
    background: rgb(var(--accent), 0.1);
  }
}

.results {
  padding: 0;
  margin: -2px 0 0;
  list-style: none;
  user-select: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 200ms, opacity 200ms, margin 200ms;
  border-bottom: 2px solid rgb(var(--accent), 0.2);

  &.show {
    opacity: 1;
    margin: 0;
  }

  li {
    overflow: hidden;
    line-height: 22px;
    height: 48px;
    font-size: 24px;
    font-weight: 600;
    transition: padding 200ms, opacity 200ms;
    opacity: 0.5;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      position: absolute;
      height: 3px;
      background: rgb(var(--accent));
      top: calc(50% - 1.5px);
      left: 0;
      content: "";
      opacity: 0;
      // border-radius: 100px;

      width: 0;
      transition: width 200ms, opacity 200ms;
    }

    &.selected {
      padding: 0 0 0 20px;
      opacity: 1;

      &::before {
        opacity: 1;
        width: 12px;
      }
    }

    .extra {
      color: rgb(var(--accent));
    }
  }
}

.flipped {
  .results {
    li {
      flex-direction: row-reverse;
      &::before {
        right: 0;
        left: auto;
      }

      &.selected {
        padding: 0 20px 0 0;
      }
    }
  }
}
