.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 8px;

  a[href]:not(:global(.default)) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      border-bottom: 2px solid transparent;
      transition: border-color 200ms;

      pointer-events: none;
    }

    &:hover::after {
      border-color: rgb(var(--accent));
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(var(--accent), 0);
    transition: background-color 200ms;

    &:hover {
      background: rgb(var(--accent), 0.2);
    }
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(var(--accent), 0.1);
    transition: background-color 200ms;

    &:hover {
      background: rgb(var(--accent), 0.5);
    }
  }
}

.column {
  padding: 0 10px 10px;
  --accent: var(--left);

  @media (max-width: 600px) {
    padding: 0 0 10px;
  }
}

.legends {
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: end;
  gap: 16px;
  text-align: center;
  margin: 20px 0;

  figure {
    margin: 0;
    padding: 0;
    position: relative;

    &::before {
      content: "";
      width: 64px;
      height: 64px;
      background: rgb(var(--accent), 0.1);
      position: absolute;
      top: 4px;
      left: calc(50% - 32px);
      // border-radius: 6px;
      // border-radius: 100%;
      z-index: 1;
    }

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      position: relative;
      z-index: 2;
    }

    figcaption {
      margin-top: px;
      font-size: 18px;
      font-weight: 900;

      span {
        display: inline-block;
        background: rgb(var(--accent), 0.1);
        // border-radius: 6px;
        width: 64px;
        padding: 4px 12px;
        // border-radius: 6px;
      }
    }
  }
}

.flipped {
  text-align: right;
  --accent: var(--right);

  .legends {
    direction: ltr;
    img {
      transform: scaleX(-1);
    }
  }
}

.middle {
  --accent: 100, 100, 100;
  // padding: 0 0px 10px 0;
}
