.matchups {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  text-align: center;
  gap: 0 10px;
  margin: 10px 0;

  > span {
    font-weight: 600;
    font-size: 13px;
    opacity: 0.5;
  }
}

.score {
  font-weight: 600;
  font-size: 20px;

  .preloader {
    opacity: 0.3;
    &::after {
      content: "Loading...";
    }
  }
}

.dash {
  opacity: 0.5;
}

.percent {
  margin-left: 8px;
  // opacity: 0;
}

.progress {
  // margin-top: 10px;
  margin: 8px 1px 0;
  width: calc(100% - 2px);
  height: 8px;
  position: relative;

  display: flex;
  opacity: 1;

  &::before,
  &::after {
    content: "";
    width: 1px;
    height: 2px;
    background: white;
    // border-radius: 100%;
    position: absolute;
    opacity: 0.4;
    left: calc(50% - 1px);
    // transform: skew(-5deg);
  }

  &::before {
    top: -2px;
  }

  &::after {
    bottom: -2px;
  }

  > div {
    flex: 0 0 auto;
    position: relative;
    height: 100%;
    background: rgb(var(--left));
    transition: width 200ms;
    transform: skew(-5deg);
    z-index: 1;

    // border-radius: 6px 0 0 6px;

    &:first-child::after {
      content: "";
      // border-radius: 10px;
      position: absolute;
      top: -2px;
      right: -1px;
      width: 2px;
      height: calc(100% + 4px);
      background: rgb(255, 255, 255);
      opacity: 1;
      z-index: 5;
      // transform: skew(5deg);
    }

    &:last-child {
      flex-grow: 1;
      background: rgb(var(--right));
      z-index: -1;
      // border-radius: 0 6px 6px 0;
    }
  }
}
