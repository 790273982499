.header {
  display: flex;
  align-items: center;
  // border-bottom: 1px solid rgb(91, 195, 205, 0.2);
  padding-right: 8px;

  .logo {
    display: flex;
    gap: 0px;
    height: 56px;
    align-items: center;

    img {
      width: 56px;
      height: 56px;
      display: block;
    }

    h1 {
      font-size: 24px;
      line-height: 1;
      span {
        display: block;
        font-size: 16px;
        color: var(--faded);
        font-weight: 600;
      }
    }
  }

  .spacer {
    flex: 1 1 auto;
  }

  nav {
    display: flex;
    gap: 8px;
    margin-left: 16px;

    a {
      display: block;
      padding: 6px 12px 8px;
      font-weight: 600;
      // border-radius: 6px;
      color: var(--faded);
      transition: background 150ms, color 150ms;

      &:hover {
        background: rgb(var(--blue), 0.3);
        color: white;
      }

      &:global(.active) {
        color: white;
        background: rgb(var(--blue), 0.4);
      }
    }
  }

  aside {
    margin-right: 12px;
    font-weight: 600;

    span {
      font-size: 14px;
      color: rgb(var(--orange), 0.8);
    }

    div {
      margin-top: -2px;
    }
    // display: flex;
    // gap: 4px;
    // align-items: cen;
  }
}

@media (max-width: 665px) {
  .header {
    flex-wrap: wrap;
    padding: 0 0 8px 0;

    .logo img {
      width: 48px;
      height: 48px;
    }

    button {
      margin-right: 8px;
    }
    nav {
      margin-left: 0;
      padding: 0 4px 4px;
      // margin-r
      border-bottom: 1px solid rgb(100, 100, 100, 0.3);
      order: 1;
      flex: 0 0 100%;
    }
  }
}
