@keyframes pulseOpacity {
  from {
    opacity: 0.1;
  }
  50% {
    opacity: 0.3;
  }
  to {
    opacity: 0.1;
  }
}

span.blank::after {
  content: "—";
  opacity: 0.1;
}

.disabled {
  opacity: 0.3;
}

.header {
  line-height: 40px;
  cursor: text;

  &.static {
    cursor: default;

    pointer-events: none;
  }
}

.name {
  margin: 0;
  font-weight: 900;
  font-size: 40px;
  padding: 7.5px 0;
  // border-radius: 6px;
  transition: opacity 250ms, background 200ms;

  .sponsor {
    color: rgb(var(--accent));
    margin-right: 8px;
  }

  &.preloader {
    // opacity: 0.3;
    animation: pulseOpacity 1s infinite;
  }
}

.header:hover .name {
  background: rgb(var(--accent), 0.1);
}

.social {
  display: flex;
  gap: 8px;
  margin-top: 2px;

  &.flipped {
    flex-direction: row-reverse;
  }

  a {
    color: rgb(var(--accent));
    transition: color 200ms;

    &:hover {
      color: white;
    }
  }
}

.meta {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  margin: 12px 0;
  padding: 0;
  list-style: none;
  align-items: center;
  gap: 5px 20px;
  font-weight: 600;

  div {
    display: flex;
    gap: 6px;
  }

  svg {
    display: block;
    width: 20px;
    color: rgb(var(--accent));
    transform: translateY(-1px);
  }

  a[href] {
    transition: color 200ms;

    &:hover {
      color: rgb(var(--accent));
    }
  }
}

.pr {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 12px 0;

  gap: 16px;
  white-space: nowrap;

  figure {
    margin: 0;
    padding: 8px 8px 10px;
    text-align: center;
    background: rgb(var(--accent), 0.2);
    // border-radius: 6px;
    position: relative;

    div {
      font-size: 36px;
      font-weight: 900;
    }

    figcaption {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 900;
      color: rgba(var(--accent), 1);
    }
  }

  &.preloader figure div {
    &::after {
      content: "—";
      animation: pulseOpacity 1s infinite;
    }
  }

  @media (max-width: 1900px) {
    figure div {
      font-size: 28px;
    }
  }

  @media (max-width: 1650px) {
    gap: 8px;
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    figure {
      padding: 4px;
    }
  }
}

.flipped.meta {
  direction: rtl;
  > * {
    direction: ltr;
  }

  div {
    flex-direction: row-reverse;
  }
}

@media (max-width: 600px) {
  .header.flipped {
    text-align: left;
  }

  .social.flipped {
    flex-direction: row;
  }

  .meta.flipped {
    direction: ltr;
    div {
      flex-direction: row;
    }
  }
}
