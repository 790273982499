.upcoming {
  > div {
    background: rgb(var(--accent), 0.2);
    // border-radius: 6px;
    cursor: pointer;
    margin-bottom: 6px;
    padding: 6px;
    transition: background 200ms;

    &:hover {
      background: rgb(var(--accent), 0.3);
    }

    &.active {
      background: rgb(var(--accent), 0.4);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        margin: 0px 0 0;
        color: rgb(var(--accent));
      }
    }

    .date {
      color: rgb(var(--accent));
    }

    main {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;

      > * {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      svg {
        display: block;
        width: 20px;
        opacity: 0.5;
      }
    }
  }
}
