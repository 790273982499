.button {
  padding: 4px 8px;
  font: inherit;
  font-weight: 800;
  font-size: 14px;
  color: white;
  background: var(--orange-1);
  border: 0;
  // border-radius: 6px;
  padding: 6px 8px 7px;
  transition: transform 200ms, box-shadow 200ms;
  text-transform: uppercase;
  background-position: right;
  box-shadow: 0 3px 0 var(--orange-2);
  margin-bottom: 3px;

  &:hover {
    background-position: left;
  }

  &:active {
    box-shadow: 0 0 0 var(--orange-2);
    transform: translateY(3px);
  }

  &.icon {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
      width: 20px;
    }
  }
}
