.tabGroup {
  > header {
    display: flex;
    white-space: nowrap;
    user-select: none;
    overflow-x: auto;
    margin-bottom: 4px;

    > div {
      font-weight: 600;
      opacity: 0.5;
      border-bottom: 2px solid transparent;
      padding: 4px 6px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
      &.active {
        opacity: 1;
        border-color: white;
      }
    }
  }
}
