@keyframes pulseOpacity {
  from {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

.placements {
  user-select: none;
  > table {
    width: 100%;
    border-spacing: 0 4px;
  }

  .event {
    cursor: pointer;
    td {
      background: rgb(var(--accent), 0.15);
    }

    &:hover {
      td {
        background: rgb(var(--accent), 0.2);
      }
    }
  }

  .preloader {
    height: 40px;
    animation: pulseOpacity 1s infinite;

    &:nth-child(5n + 4) {
      animation-delay: -200ms;
    }
    &:nth-child(5n + 3) {
      animation-delay: -400ms;
    }

    &:nth-child(5n + 2) {
      animation-delay: -600ms;
    }
    &:nth-child(5n + 1) {
      animation-delay: -800ms;
    }

    td {
      // border-radius: 6px;
    }
  }
}

.placement {
  width: 0;
  font-weight: 800;
  font-size: 18px;
  padding: 0 8px;
  text-align: center;
  // border-radius: 6px 0 0 6px;
  background: rgb(var(--accent), 0.3) !important;

  &.right {
    // border-radius: 0 6px 6px 0 !important;
  }
}

.name {
  font-weight: 600;
  line-height: 1.1;
  padding: 4px 8px;

  div {
    direction: ltr;
  }

  .evt {
    font-weight: 700;
  }

  .tourney {
    color: rgb(var(--accent));
    font-size: 14px;
  }
}

.date {
  width: 0;
  text-align: center;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  padding: 0 8px;
  // border-radius: 0 6px 6px 0;
  color: rgb(var(--accent));
  font-weight: 600;
}

.active {
  .date {
    border-bottom-right-radius: 0;
  }
}

.noMatches {
  text-align: center;
  font-weight: 500;
  color: rgb(var(--accent));
  padding: 8px 0;
  direction: ltr;
}

.container {
  display: grid;
  align-items: stretch;
  grid-template-columns: 1fr auto auto auto 1fr auto;
  grid-auto-rows: 34px;
  padding: 0;

  &.noLegends {
    grid-template-columns: auto auto auto 1fr;

    .legends {
      display: none;
    }
  }

  > * {
    border-top: 1px solid rgb(var(--accent), 0.2);
    display: flex;
    align-items: center;
    // border-radius: 6px;

    &.highlight {
      background: rgb(var(--accent), 0.15);
    }

    &:last-child {
      // border-radius: 0 0 6px 0;
    }
  }
}

.score {
  font-weight: 600;
  text-align: left;
  padding: 0 6px;
  .dq {
    opacity: 0.5;
    color: rgb(var(--accent));
  }
}

.divider {
  font-weight: 600;
  color: rgb(var(--accent));
  padding: 0 4px;
}

.opponent {
  white-space: nowrap;
  padding: 0 6px 0 8px;
  font-weight: 600;
  justify-content: flex-end;
}

.legends {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 24px;
  padding: 0 6px 0 6px;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    object-position: center;
    transform: translateX(-3px);

    &.unknown {
      filter: drop-shadow(0 0 2px rgb(var(--accent)));
      opacity: 0.3;
      width: 26px;
      height: 26px;
      margin: 3px;
    }
  }

  &.flip {
    justify-content: end;

    img {
      transform: translateX(-3px) scaleX(-1);
    }
  }
}

.matches {
  // direction: ltr;
  // text-align: left;
  td {
    // border-radius: 0 0 6px 0;
    background: rgb(var(--accent), 0.05) !important;
  }
}

.flipped {
  > table {
    direction: rtl;
  }

  .placement {
    // border-radius: 0 6px 6px 0;
  }

  .date {
    // border-radius: 6px 0 0 6px;
    text-align: left;
  }

  .active {
    .date {
      border-bottom-left-radius: 0;
    }
  }

  .matches td {
    // border-radius: 0 0 0 6px;
  }

  .legends {
    img {
      transform: scaleX(-1);
    }

    &.flip img {
      transform: scaleX(1);
    }
  }

  .opponent {
    padding: 0 8px 0 6px;
    // text-align: left;
    justify-content: flex-start;
    direction: ltr;
  }

  .container > :last-child {
    // border-radius: 0 0 0 6px;
  }
}

.matchup {
  .event {
    cursor: default;
    &:hover td {
      background: rgb(var(--accent), 0.15);
    }
  }
  .date {
    // border-radius: 0 !important;
  }

  .container {
    grid-template-columns: 1fr auto auto auto 1fr;

    &.noLegends {
      grid-template-columns: 1fr auto 1fr;
      justify-content: center;
    }

    :last-child {
      // border-radius: 0 !important;
    }
  }
}

tr {
  &.left {
    --accent: var(--left);
  }
  &.right {
    --accent: var(--right);
  }

  // background: rgb(var(--accent), 0.8) !important;
}

.attributes {
  td {
    background: rgb(var(--accent), 0.05);
  }
  td > div {
    display: flex;
    gap: 8px;
    font-weight: 600;
    padding: 2px 8px;
    justify-content: space-between;
    align-items: center;
    color: rgb(var(--accent));
  }

  a {
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }

    color: rgb(var(--accent));
    transition: color 200ms;

    &:hover {
      color: white;
    }
  }
}

@media (max-width: 1150px) {
  .matchup.placements {
    max-height: 400px;
    overflow: auto;
  }
}
