.container {
  img {
    display: block;
  }

  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  --accent: 150, 150, 150;

  .left,
  .right {
    > * {
      margin: 0 0 20px;
    }
  }

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 12px;

    .middle {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .middle {
      grid-column: 1;
    }

    .left,
    .right {
      margin: 0 -12px;
      padding: 0 12px;

      > * {
        margin: 12px 0;
      }
    }

    .team {
      margin-bottom: 6px;
    }

    .left {
      background: rgb(var(--accent), 0.1);
    }
    .right {
      background: rgb(var(--accent), 0.1);
    }
  }
}

.statusHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .status {
    opacity: 0.5;
    transform: translateY(1px);
  }
  a[href] {
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      display: block;
      width: 20px;
      opacity: 0.5;
    }
  }
}

.left {
  --accent: var(--left);
}

.right {
  --accent: var(--right);
}

.stat {
  > header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    .legend {
      position: relative;

      &::before {
        content: "";
        width: 60px;
        height: 60px;
        background: rgb(var(--accent), 0.15);
        position: absolute;
        top: 2px;
        left: 2px;
        // border-radius: 100%;
      }

      img {
        position: relative;
        z-index: 2;
        width: 64px;
        height: 64px;
        object-fit: contain;
        object-position: center;
      }
    }

    h2 {
      margin: 0;
      font-size: 32px;
      font-weight: 900;

      .sponsor {
        margin-right: 6px;
        opacity: 0.4;
      }
    }

    h3 {
      margin: -5px 0 0;
      color: rgb(var(--accent));
      font-weight: 700;
    }
  }

  figure {
    padding: 4px 0;
    margin: 0;
    text-align: center;
    background: rgb(var(--accent), 0.15);
    // border-radius: 6px;

    > div {
      font-weight: 800;
      letter-spacing: -0.07em;
    }

    > figcaption {
      font-size: 12px;
      color: rgb(var(--accent));
      font-weight: 900;
      text-transform: uppercase;
      margin-top: -5px;
    }

    &.best {
      color: black;
      background: rgb(var(--accent));
      > figcaption {
        color: black;
      }
    }
  }

  &.flipped {
    > header {
      text-align: right;
      flex-direction: row-reverse;

      .legend img {
        transform: scaleX(-1);
      }
    }
  }

  @media (max-width: 600px) {
    &.flipped > header {
      text-align: left;
      flex-direction: row;
      .legend img {
        transform: none;
      }
    }
  }
}

.damage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  font-size: 40px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
}

.attackType {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 12px;
  margin: 12px 0;
  font-size: 32px;

  > div {
    grid-column: span 2;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    // color: rgb(var(--accent));
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 32px);
      height: 100%;
      border: 2px solid rgb(var(--accent), 0.5);
      bottom: -24px;
      left: 16px;
      // border-radius: 6px;
      clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    }
  }
}

.weaponDamage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin: 12px 0;

  section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: rgb(var(--accent), 0.15);
    // border-radius: 6px;
  }

  header {
    grid-column: span 2;
    gap: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 6px;
    // border-bottom: 1px solid rgb(var(--accent), 0.1);
    background: rgb(var(--accent), 0.1);
    // border-radius: 6px 6px 0 0;
    // text-align: center;
    // justify-content: center;
    img {
      flex: 0 0 auto;
      width: 32px;
      height: 32px;
      opacity: 1;
    }

    h4 {
      margin: 0;
      font-weight: 800;
    }

    h5 {
      margin: -4px 0 0;
      font-size: 14px;

      font-weight: 800;
      color: rgb(var(--accent));
    }
  }

  figure {
    padding-top: 0;
    font-size: 28px;
    background: transparent;
  }

  @media (max-width: 930px) {
    img {
      display: none;
    }
  }
  @media (max-width: 600px) {
    figure {
      font-size: 28px;
    }
  }
}

.na {
  opacity: 0.3;
  color: rgb(var(--accent));
}

.team {
  margin-top: 8px;
  padding: 0 12px;
  white-space: nowrap;

  main {
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    > * {
      // overflow: hidden;
      border-bottom: 2px solid rgb(255, 255, 255, 0.1);
      padding: 4px 0;

      &:nth-last-child(-n + 3) {
        border-bottom: 0;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-column: 2;
      text-align: center;
      margin: 0;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 800;
      color: rgb(150, 150, 150);
    }

    > div {
      --accent: var(--left);
      font-size: 36px;
      letter-spacing: -0.07em;
      font-weight: 800;
      color: rgb(var(--accent));
      margin: 0 0 0 -8px;
      padding: 4px 0 4px 8px;

      &:nth-of-type(2n) {
        --accent: var(--right);
        text-align: right;

        margin: 0 -8px 0 0;
        padding: 4px 8px 4px;
      }

      &.best {
        color: white;
        text-shadow: 0 0 2px rgb(var(--accent)), 0 0 10px rgb(var(--accent));
      }
    }
  }

  @media (max-width: 1150px) {
    padding: 0;
    margin: 20px 0;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 14px;
    }
    div {
      font-size: 28px;
    }
  }
}

.scoreboard {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  .scoreboardTeam {
    --accent: var(--left);

    &.flipped {
      --accent: var(--right);
    }
  }

  .row {
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 8px;

    .legend {
      position: relative;

      img {
        display: block;
        height: 48px;
        width: 48px;
        object-fit: contain;
      }

      &::before {
        content: "";
        width: 46px;
        height: 46px;
        background: rgb(var(--accent), 0.2);
        // border-radius: 100%;
        position: absolute;
        top: 1px;
        left: 1px;
      }
    }

    .name {
      line-height: 1.1;

      span {
        line-height: 1;
        display: block;
        font-size: 14px;
        opacity: 0.5;
      }
    }
  }
  .flipped .row {
    flex-direction: row-reverse;
    text-align: right;

    img {
      transform: scaleX(-1);
    }
  }

  .score {
    font-size: 18px;
    span {
      opacity: 0.5;
    }
  }

  @media (max-width: 600px) {
    // grid-template-columns: 1fr;
    // align-items: center;

    // .scoreboardTeam {
    //   display: flex;
    // }

    .row .legend {
      img {
        width: 32px;
        height: 32px;
      }
      &::before {
        width: 34px;
        height: 34px;
        top: -1px;
        left: -1px;
      }
    }
  }
}

.tabgroup {
  .activeSelector {
    display: none;
    user-select: none;

    div {
      --accent: var(--left);
      text-align: center;
      padding: 8px 0;
      border-bottom: 2px solid transparent;
      text-transform: uppercase;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.5);

      transition: border-color 250ms, color 250ms;

      &:nth-child(2) {
        --accent: var(--right);
      }

      &.active {
        color: rgb(var(--accent));
        border-color: rgb(var(--accent));
      }
    }
  }

  @media (max-width: 600px) {
    .activeSelector {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .left:not(.active) {
      display: none;
    }
    .right:not(.active) {
      display: none;
    }
  }
}
