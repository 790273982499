.opponents {
  width: 100%;
  white-space: nowrap;
  font-weight: 500;
  border-spacing: 0 4px;

  th {
    text-align: left;
    font-size: 14px;
    opacity: 0.5;
    padding: 4px;
    font-weight: 600;
  }

  td {
    padding: 6px 4px 6px 6px;
    background: rgb(var(--accent), 0.3);
    &:first-child {
      // border-radius: 6px 0 0 6px;
    }

    &:last-child {
      // border-radius: 0 6px 6px 0;
    }

    &:nth-child(5) {
      background: rgb(var(--accent), 0.4);
    }
  }
}

.left {
  text-align: right;
}

.dash {
  color: rgba(255, 255, 255, 0.5);
}

.percent {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 6px !important;
}

.opponent {
  width: 100%;
  padding-left: 6px !important;
}

.leftW {
  --accent: var(--left);
}

.rightW {
  --accent: var(--right);
}

.container > header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  user-select: none;
  cursor: pointer;

  > div {
    --accent: var(--left);
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 0;
    border-bottom: 2px solid transparent;
    transition: border-color 200ms, color 200ms;

    &:last-child {
      --accent: var(--right);
    }

    &.active {
      color: rgb(var(--accent));
      border-color: rgb(var(--accent));
    }
  }
}
